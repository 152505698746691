import { get } from 'lodash';
import Vue from 'vue';
import Pusher from 'pusher-js';

import cookies from '@/utils/cookies';
import {
  fetchFoodCourtDetailsRequest,
  fetchFoodCourtOrdersRequest,
  fetchFoodCourtRestaurantsRequest,
  fetchFoodCourtTranslationsRequest,
  fetchRestaurantSessionRequest,
  updateFoodCourtSessionLanguageRequest,
} from './api';
import router from '../../../router';

const initialState = {
  foodCourtSession: cookies.getCookie('foodCourtSession') || null,
  foodCourtHash: cookies.getCookie('foodCourtHash') || null,
  restaurantSessionMap:
    JSON.parse(cookies.getCookie('restaurantSessionMap')) || {},
  foodCourt: {},
  language: null,
  restaurants: [],
  orders: [],
  fetchedLanguages: [],
  subscribedToPusherChannels: null,
  foodCourtStatus: 'idle',
  restaurantsStatus: 'idle',
  foodCourtOrdersStatus: 'idle',
};

// Food court cookies TTL in hours
const TTL = 6;

export default {
  state: initialState,
  mutations: {
    setFoodCourt(state, foodCourt) {
      state.foodCourt = foodCourt;
    },
    setFoodCourtSession(state, foodCourtSession) {
      state.foodCourtSession = foodCourtSession;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setRestaurants(state, restaurants) {
      state.restaurants = restaurants;
    },
    setFoodCourtStatus(state, foodCourtStatus) {
      state.foodCourtStatus = foodCourtStatus;
    },
    setRestaurantsStatus(state, restaurantsStatus) {
      state.restaurantsStatus = restaurantsStatus;
    },
    setFoodCourtOrdersStatus(state, foodCourtOrdersStatus) {
      state.foodCourtOrdersStatus = foodCourtOrdersStatus;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setRestaurantSession(state, { restaurantId, session }) {
      state.restaurantSessionMap[restaurantId] = session;
    },
    setFoodCourtHash(state, hash) {
      state.foodCourtHash = hash;
    },
    addFetchedLanguage(state, language) {
      state.fetchedLanguages.push(language);
    },
    setSubscribedToPusherChannels(state, id) {
      state.subscribedToPusherChannels = id;
    },
    updateRestaurantStatus(
      state,
      { restaurantId, status, rushModeWaitMinutes }
    ) {
      const restaurant = state.restaurants.find(
        restaurant => restaurant.id === restaurantId
      );
      if (restaurant) {
        restaurant.order_status = status;
        restaurant.rush_mode_wait_minutes = rushModeWaitMinutes;
      }
    },
    updateRestaurantIsCurrentlyWorkingHours(state, { restaurantId, status }) {
      const restaurant = state.restaurants.find(
        restaurant => restaurant.id === restaurantId
      );
      if (restaurant) {
        restaurant.is_currently_working_hours = status;
      }
    },
  },
  actions: {
    setFoodCourtHash({ commit, dispatch }, { hash }) {
      commit('setFoodCourtHash', hash);
      dispatch('saveFoodCourtHashCookie', { hash });
    },
    async fetchFoodCourtDetails({ commit, getters, dispatch }, { hash }) {
      // Do not show food court selection for Kiosk
      if (getters.tableTypeIsSelfService) {
        return;
      }

      const foodCourtDataAlreadyFetched = Object.keys(
        getters.getFoodCourtDetails
      ).length;

      if (foodCourtDataAlreadyFetched) {
        return;
      }

      try {
        commit('setFoodCourtStatus', 'loading');

        const foodCourtSession = getters.getFoodCourtSession;
        const foodCourtHash = getters.getFoodCourtHash;

        const { data } = await fetchFoodCourtDetailsRequest({
          hash,
          foodCourtSession,
        });

        if (!foodCourtHash || foodCourtHash !== hash) {
          commit('setFoodCourtHash', hash);
          dispatch('saveFoodCourtHashCookie', { hash });

          // Redirect to food court page if user shared a restaurant link
          router.replace({
            name: 'FoodCourt',
            params: { foodCourtHash: hash },
          });
        }

        if (!foodCourtSession) {
          commit('setFoodCourtSession', data.food_court_session);
          dispatch('saveFoodCourtSessionCookie');
        }

        commit('setFoodCourt', data.food_court);
        commit('setLanguage', data.language);

        dispatch('fetchFoodCourtOrders');

        // Set translations
        const translations = (await import(`../../../i18n/${data.language}.js`))
          .default;
        Vue.i18n.add(data.language, translations);
        Vue.i18n.set(data.language);
        dispatch('fetchFoodCourtTranslations', {
          hash,
          languageCode: data.language,
        });

        commit('setFoodCourtStatus', 'idle');
      } catch (error) {
        console.error(error);
        commit('setFoodCourtStatus', 'idle');
      }
    },
    async fetchFoodCourtRestaurants({ commit, getters }, { hash }) {
      const foodCourtRestaurantsAlreadyFetched =
        getters.getFoodCourtRestaurants.length;

      if (foodCourtRestaurantsAlreadyFetched) {
        return;
      }

      try {
        commit('setRestaurantsStatus', 'loading');
        const { data } = await fetchFoodCourtRestaurantsRequest({ hash });
        commit('setRestaurants', data.data);
        commit('setRestaurantsStatus', 'idle');
      } catch (error) {
        console.error(error);
        commit('setRestaurantsStatus', 'idle');
      }
    },
    async fetchRestaurantSession(
      { getters, commit, dispatch },
      { restaurantId, onSuccess }
    ) {
      try {
        const foodCourtSession = getters.getFoodCourtSession;
        const existingSession = getters.getRestaurantSession(restaurantId);

        if (existingSession) {
          onSuccess(existingSession);
          return;
        }

        const { data } = await fetchRestaurantSessionRequest({
          restaurantId,
          foodCourtSession,
        });
        commit('setRestaurantSession', {
          restaurantId,
          session: data.session,
        });
        dispatch('saveRestaurantSessionMapCookie');
        onSuccess(data.session);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFoodCourtOrders({ getters, commit }) {
      try {
        commit('setFoodCourtOrdersStatus', 'loading');
        const session = getters.getFoodCourtSession;
        const { data } = await fetchFoodCourtOrdersRequest({
          foodCourtSession: session,
        });
        commit('setOrders', data.data);
        commit('setFoodCourtOrdersStatus', 'idle');
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFoodCourtTranslations(
      { commit, getters },
      { hash, languageCode }
    ) {
      const languageAlreadyFetched = getters.getFetchedLanguageByCode(
        languageCode
      );

      if (languageAlreadyFetched) {
        return;
      }

      try {
        const { data } = await fetchFoodCourtTranslationsRequest({
          hash,
          languageCode,
        });
        Vue.i18n.add(languageCode, data);
        commit('addFetchedLanguage', languageCode);
      } catch (error) {
        console.error(error);
      }
    },
    saveRestaurantSessionMapCookie({ state }) {
      const restaurantSessionMap = JSON.stringify(state.restaurantSessionMap);
      cookies.setCookie({
        name: 'restaurantSessionMap',
        value: restaurantSessionMap,
        hours: TTL,
      });
    },
    saveFoodCourtSessionCookie({ state }) {
      cookies.setCookie({
        name: 'foodCourtSession',
        value: state.foodCourtSession,
        hours: TTL,
      });
    },
    saveFoodCourtHashCookie(_, { hash }) {
      cookies.setCookie({ name: 'foodCourtHash', value: hash, hours: TTL });
    },
    subscribeToFoodCourtChannels({ getters, dispatch, commit }) {
      const foodCourtSession = getters.getFoodCourtSession;
      const foodCourtHash = getters.getFoodCourtHash;
      const foodCourtId = getters.getFoodCourtId;
      const alreadySubscribedSession = getters.getIsSubscribedToPusherChannels;
      const isAlreadySubscribed = alreadySubscribedSession === foodCourtSession;

      if (isAlreadySubscribed || !foodCourtSession || !foodCourtId) {
        return;
      }

      Pusher.logToConsole = process.env.VUE_APP_ENVIRONMENT !== 'production';
      const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: 'eu',
      });

      const foodCourtOrdersChanel = pusher.subscribe(
        `food-court-session_${foodCourtSession}`
      );
      const foodCourtChanel = pusher.subscribe(`food-court_${foodCourtId}`);

      commit('setSubscribedToPusherChannels', foodCourtSession);

      const openOrder = ({ session, unique_order_number }) => {
        router.push({
          name: 'ViewFoodCourtOrder',
          params: { session, uniqueOrderNumber: unique_order_number },
        });
      };

      const showToastNotification = ({
        title,
        description,
        action,
        restaurantName,
      }) => {
        const toastTitle = restaurantName
          ? `${restaurantName}. ${title}`
          : title;
        dispatch('showToastInstantly', {
          title: toastTitle,
          description,
          action,
        });
      };

      const handleOrderSeenByWaiter = data => {
        dispatch('fetchFoodCourtOrders');
        showToastNotification({
          title: Vue.i18n.translate('components.toast.orderSeenTitle'),
          description: Vue.i18n.translate(
            'components.toast.orderSeenDescription'
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };
      const handleOrderFailed = data => {
        dispatch('fetchFoodCourtOrders');
        showToastNotification({
          title: Vue.i18n.translate('components.toast.orderFailedTitle'),
          description: Vue.i18n.translate(
            'components.toast.orderFailedDescription'
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };
      const handleOrderAccepted = data => {
        dispatch('fetchFoodCourtOrders');
        showToastNotification({
          title: Vue.i18n.translate('components.toast.orderAcceptedTitle'),
          description: Vue.i18n.translate(
            'components.toast.orderAcceptedDescription',
            { restaurantName: data.restaurant_name }
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };
      const handleOrderItemDelivered = data => {
        showToastNotification({
          title: Vue.i18n.translate('components.toast.orderItemDeliveredTitle'),
          description: Vue.i18n.translate(
            'components.toast.orderItemDeliveredDescription'
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };
      const handleOrderFinished = data => {
        dispatch('fetchFoodCourtOrders');
        showToastNotification({
          title: Vue.i18n.translate('components.toast.orderFinishedTitle'),
          description: Vue.i18n.translate(
            'components.toast.orderFinishedDescription'
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };
      const handleWaitTimeProlonged = data => {
        dispatch('fetchFoodCourtOrders');
        showToastNotification({
          title: Vue.i18n.translate(
            'components.toast.orderWaitTimeProlongedTitle'
          ),
          description: Vue.i18n.translate(
            'components.toast.orderWaitTimeProlongedDescription'
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };
      const handleOrderRejected = data => {
        dispatch('fetchFoodCourtOrders');
        showToastNotification({
          title: Vue.i18n.translate('components.toast.orderRejectedTitle'),
          description: Vue.i18n.translate(
            'components.toast.orderRejectedDescription'
          ),
          restaurantName: data.restaurant_name,
          action: () => openOrder(data),
        });
      };

      const handleRushModeEnabled = data => {
        const time = data.rush_mode_wait_minutes;
        const restaurantId = data.restaurant_id;
        const showRushModeToast = getters.getShouldShowRushModeToast(
          data.restaurant_id
        );

        commit('updateRestaurantStatus', {
          restaurantId,
          status: 'rush_mode',
          rushModeWaitMinutes: time,
        });

        dispatch('fetchFoodCourtRestaurants', { hash: foodCourtHash });

        if (!showRushModeToast) {
          return;
        }

        showToastNotification({
          title: Vue.i18n.translate('components.toast.rushMode.title'),
          description: Vue.i18n.translate(
            'components.toast.rushMode.description',
            { time }
          ),
          restaurantName: data.restaurant_name,
        });
      };

      const handleRushModeDisabled = data => {
        const restaurantId = data.restaurant_id;

        commit('updateRestaurantStatus', {
          restaurantId,
          status: 'open',
          rushModeWaitMinutes: null,
        });

        dispatch('fetchFoodCourtRestaurants', { hash: foodCourtHash });
      };

      const handleOrderCreated = () => dispatch('fetchFoodCourtOrders');

      const handleRestaurantClosed = data => {
        const restaurantId = data.restaurant_id;

        commit('updateRestaurantStatus', {
          restaurantId,
          status: 'temporarily_disabled',
          rushModeWaitMinutes: null,
        });
      };

      const handleRestaurantOpened = data => {
        const restaurantId = data.restaurant_id;

        commit('updateRestaurantStatus', {
          restaurantId,
          status: 'open',
          rushModeWaitMinutes: null,
        });
      };

      const handleWorkingHoursUpdated = data => {
        const isWorkingHours = data.is_currently_working_hours;
        const restaurantId = data.restaurant_id;
        
        commit('updateRestaurantIsCurrentlyWorkingHours', {
          restaurantId,
          status: isWorkingHours,
        });
      };

      const foodCourtOrderEvents = [
        { event: 'order-seen-by-waiter', handler: handleOrderSeenByWaiter },
        { event: 'order-failed', handler: handleOrderFailed },
        { event: 'order-accepted', handler: handleOrderAccepted },
        { event: 'order-item-delivered', handler: handleOrderItemDelivered },
        { event: 'order-finished', handler: handleOrderFinished },
        {
          event: 'order-wait-time-prolonged',
          handler: handleWaitTimeProlonged,
        },
        { event: 'order-rejected', handler: handleOrderRejected },
        { event: 'order-created', handler: handleOrderCreated },
      ];

      const foodCourtEvents = [
        { event: 'rush-mode-activated', handler: handleRushModeEnabled },
        { event: 'rush-mode-deactivated', handler: handleRushModeDisabled },
        { event: 'restaurant-closed', handler: handleRestaurantClosed },
        { event: 'restaurant-opened', handler: handleRestaurantOpened },
        {
          event: 'is-currently-working-hours-updated',
          handler: handleWorkingHoursUpdated,
        },
      ];

      // Subscribe to all food court order events
      foodCourtOrderEvents.forEach(({ event, handler }) => {
        foodCourtOrdersChanel.bind(event, handler);
      });

      // Subscribe to all food court events
      foodCourtEvents.forEach(({ event, handler }) => {
        foodCourtChanel.bind(event, handler);
      });
    },
    updateFoodCourtSessionLanguage({ getters, commit }, { languageCode }) {
      const foodCourtSession = getters.getFoodCourtSession;

      if (!foodCourtSession) {
        return;
      }

      commit('setLanguage', languageCode);
      updateFoodCourtSessionLanguageRequest({
        foodCourtSession,
        languageCode,
      });
    },
  },
  getters: {
    getFoodCourtDetails(state) {
      return state.foodCourt;
    },
    getFoodCourtId(_, getters) {
      return getters.getFoodCourtDetails.id;
    },
    getFoodCourtRestaurants(state) {
      return state.restaurants;
    },
    getFoodCourtRestaurantById: state => restaurantId => {
      return state.restaurants.find(
        restaurant => restaurant.id === restaurantId
      );
    },
    getFoodCourtLoading(state) {
      return state.foodCourtStatus === 'loading';
    },
    getRestaurantsLoading(state) {
      return state.restaurantsStatus === 'loading';
    },
    getFoodCourtOrdersLoading(state) {
      return state.foodCourtOrdersStatus === 'loading';
    },
    getFoodCourtSession(state) {
      return state.foodCourtSession;
    },
    getFoodCourtLanguage(state) {
      return state.language;
    },
    getFoodCourtLanguages(state) {
      return state.foodCourt.languages;
    },
    getFoodCourtDefaultLanguage(state) {
      return state.foodCourt.language;
    },
    getFoodCourtOrders(state) {
      return state.orders;
    },
    getRestaurantSession: state => restaurantId => {
      return get(state.restaurantSessionMap, restaurantId, null);
    },
    getFoodCourtSessionCookie(state) {
      return state.foodCourtSession;
    },
    getFoodCourtHash(state) {
      return state.foodCourtHash;
    },
    getFetchedLanguageByCode: state => language => {
      return state.fetchedLanguages.includes(language);
    },
    getIsSubscribedToPusherChannels(state) {
      return state.subscribedToPusherChannels;
    },
    getActiveOrdersCount(_, getters) {
      return getters.getFoodCourtOrders.reduce((acc, order) => {
        if (order.order_status !== 'closed') {
          acc += 1;
        }
        return acc;
      }, 0);
    },
    getRestaurantRushModeEnabled: (_, getters) => restaurantId => {
      const restaurants = getters.getFoodCourtRestaurants;
      const restaurant =
        restaurants.find(restaurant => restaurant.id === restaurantId) || {};

      return restaurant.order_status === 'rush_mode';
    },
    getShouldShowRushModeToast: (_, getters) => restaurantId => {
      const currentRestaurantId = getters.getCurrentRestaurantId;
      const restaurantRushModeEnabled = getters.getRestaurantRushModeEnabled(
        restaurantId
      );

      return restaurantId === currentRestaurantId && !restaurantRushModeEnabled;
    },
    getFoodCourtOrderOrderNumber: (_, getters) => uniqueOrderNumber => {
      const orders = getters.getFoodCourtOrders;
      return (
        orders.find(
          order => order.order.unique_order_number === uniqueOrderNumber
        )?.order || null
      );
    },
  },
};
