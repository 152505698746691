<template>
  <div class="text-center">
    <v-dialog v-model="show" width="400" persistent>
      <v-card class="mx-auto">
        <v-card-title>
          <span class="text-h5 font-weight-bold">
            {{ $t(content.title) }}
          </span>
        </v-card-title>

        <v-card-text class="text-h6 font-weight-light">
          {{ $t(content.description) }}
          <div class="d-flex justify-center py-4">
            <v-progress-circular
              v-if="loading"
              indeterminate
              color="black"
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const content = {
  resolvingUnresolvedCRNs: {
    title: 'kiosk.terminalCommands.unresolvedCRNs.title',
    description: 'kiosk.terminalCommands.unresolvedCRNs.description',
  },
  pendingSettlement: {
    title: 'kiosk.terminalCommands.pendingSettlement.title',
    description: 'kiosk.terminalCommands.pendingSettlement.description',
  },
};

export default {
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    isKiosk() {
      return this.$store.getters.isKiosk;
    },
    unresolvedCRNs() {
      return this.$store.getters.unresolvedCRNs || [];
    },
    hasUnresolvedCRNs() {
      return this.unresolvedCRNs.length > 0;
    },
    printerOnline() {
      return this.$store.getters.printerIsOnline;
    },
    posConnected() {
      return this.$store.getters.terminalOnline;
    },
    loadingUnresolvedCRNs() {
      return this.$store.getters.loadingUnresolvedCRNs;
    },
    loadingPendingSettlement() {
      return this.$store.getters.loadingPendingSettlement;
    },
    show() {
      return (
        this.isKiosk &&
        this.printerOnline &&
        this.posConnected &&
        (this.loadingUnresolvedCRNs || this.loadingPendingSettlement)
      );
    },
    content() {
      if (this.loadingUnresolvedCRNs) {
        return content.resolvingUnresolvedCRNs;
      } else if (this.loadingPendingSettlement) {
        return content.pendingSettlement;
      }

      return {};
    },
  },
};
</script>
