<template>
  <div>
    <div class="d-flex align-center">
      <img
        class="success-icon mt-4 mb"
        :class="{
          'success-icon--show': minifyOrderScreenAfterSuccess && orderFailed,
        }"
        :src="require(`@/assets/images/icons/checkmark-success.svg`)"
      />
      <span class="tp-title-bold mt-6">{{ statusData.title }}</span>
    </div>
    <div
      v-if="$store.getters.customizationOrderInfoCustomOrderStatusText"
      class="tp-text-body mt-3"
    >
      {{ $t($store.getters.customizationOrderInfoCustomOrderStatusText) }}
    </div>
    <div v-else-if="statusData.description" class="tp-text-body mt-3">
      {{ statusData.description }}
    </div>
    <div v-if="statusData.deliveryTime" class="tp-text-body mt-3">
      {{ statusData.deliveryTime }}
    </div>
    <div v-if="statusData.imageSrc" class="d-flex justify-center pt-5 pb-3">
      <img :src="statusData.imageSrc" class="status-image" />
    </div>
    <div
      v-if="statusData.intermediateIndex"
      class="status-indicators d-flex flex-row mt-5"
    >
      <v-progress-linear
        v-for="index in 3"
        :key="index"
        class="indicator"
        :indeterminate="statusData.intermediateIndex === index"
        :value="statusData.intermediateIndex > index ? 100 : 0"
        color="black"
      />
    </div>
    <div v-if="statusData.showGuestCounter">
      <FloatingAvatars
        :guest-count="guestPlacedOrdersCount"
        :unique-order-number="uniqueOrderNumber"
        :batch-activate-at="batch?.activate_at"
      />
    </div>
    <div
      v-show="!minifyOrderScreenAfterSuccess && statusData.animationSrc"
      class="animation d-flex justify-center pt-8 pb-6"
      :class="{
        'animation--hide':
          minifyOrderScreenAfterSuccess || !statusData.animationSrc,
      }"
    >
      <lottie-vue-player
        :key="statusData.animationSrc"
        :class="{
          'animation--hide': minifyOrderScreenAfterSuccess,
        }"
        :src="statusData.animationSrc"
        :loop="statusData.animationOptions.loop"
        :style="{
          width: statusData?.animationOptions?.width || '150px',
          height: statusData?.animationOptions?.width || '150px',
        }"
        autoplay
      />
    </div>
    <div v-if="statusData.disclaimerText" class="tp-text-body mt-5">
      {{ statusData.disclaimerText }}
    </div>
  </div>
</template>

<script>
import { delay } from 'lodash';
import FloatingAvatars from '@/components/Orders/OrdersView/FloatingAvatars.vue';

export default {
  components: {
    FloatingAvatars,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    payAtRestaurant: {
      type: Boolean,
      required: false,
      default: false,
    },
    deliveryTime: {
      type: String,
      required: false,
      default: null,
    },
    orderSeen: {
      type: Boolean,
      required: false,
      default: false,
    },
    batch: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    sendingToPos: {
      type: Boolean,
      required: false,
      default: false,
    },
    sentToPos: {
      type: Boolean,
      required: false,
      default: false,
    },
    uniqueOrderNumber: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      mounted: false,
      minifyOrderScreenAfterSuccess: false,
    };
  },
  computed: {
    orderFailed() {
      return this.status !== 'failed' && this.status != 'rejected';
    },
    orderFlowIsWithoutStatusChanges() {
      return this.$store.getters.orderFlowIsWithoutStatusChanges;
    },
    fullOrderFlow() {
      return this.$store.getters.orderFlowIsFull;
    },
    tableTypeIsPickup() {
      return this.$store.getters.tableTypeIsPickup;
    },
    orderStatusPartiallyDelivered() {
      return (
        this.status === 'active' &&
        this.items.filter(item => {
          return item.is_delivered;
        }).length > 0
      );
    },
    orderWaitingOthers() {
      return this.status === 'pending_activate' && this.batch;
    },
    guestCount() {
      return this.batch?.table_session_count || 0;
    },
    guestPlacedOrdersCount() {
      return this.batch?.placed_orders_count || 0;
    },
    errorStatusData() {
      const orderId = this.orderId;

      return {
        failed: {
          title: this.$t('screens.order.orderStatusFailed', {
            orderId,
          }),
          description: this.$t('screens.order.orderFailedDescription'),
          imageSrc: require('@/assets/images/order-failed-image-2.svg'),
          animationOptions: {
            width: '150px',
          },
        },
        rejected: {
          title: this.$t('screens.order.orderStatusRejected', {
            orderId,
          }),
          description: this.$t('screens.order.orderRejectedDescription'),
          imageSrc: require('@/assets/images/order-failed-image.svg'),
          animationOptions: {
            width: '150px',
          },
        },
      };
    },
    pickUpStatusData() {
      const orderId = this.orderId;
      const deliveryTime = this.deliveryTime
        ? `${this.$t('screens.order.pickUpTime')} - ${this.deliveryTime}`
        : this.$t('screens.order.pickupTimeWillBeUpdated');
      const seen = this.orderSeen;

      return {
        waiting_confirmation: {
          title: this.$t(
            'screens.order.pickup.orderStatusPendingConfirmation',
            {
              orderId,
            }
          ),
          intermediateIndex: seen ? 1 : 0,
          animationSrc: '/assets/order-status-animations/loading.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          deliveryTime,
          disclaimerText: this.$t(
            this.$store.getters
              .customizationOrderInfoStatusPendingDisclaimerText
          ),
        },
        active: {
          title: this.$t('screens.order.pickup.orderStatusPreparing', {
            orderId,
          }),
          intermediateIndex: 2,
          animationSrc: '/assets/order-status-animations/preparing-food.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          deliveryTime,
          disclaimerText: this.$t(
            this.$store.getters
              .customizationOrderInfoStatusPreparingDisclaimerText
          ),
        },
        partially_delivered: {
          title: this.$t('screens.order.pickup.orderStatusPartiallyDelivered', {
            orderId,
          }),
          intermediateIndex: 4,
          animationSrc: '/assets/order-status-animations/preparing-food.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          deliveryTime,
          disclaimerText: this.$t(
            this.$store.getters
              .customizationOrderInfoStatusPartialDeliveryDisclaimerText
          ),
        },
        closed: {
          title: this.$t('screens.order.pickup.orderStatusDelivered', {
            orderId,
          }),
          animationSrc: '/assets/order-status-animations/order-delivered.json',
          animationOptions: {
            width: '150px',
          },
          hideAnimationAfterSuccess: true,
          disclaimerText: this.$t(
            this.$store.getters.customizationOrderInfoStatusClosedDisclaimerText
          ),
        },
      };
    },
    fullOrderFlowStatusData() {
      const orderId = this.orderId;
      const deliveryTime = this.deliveryTime
        ? `${this.$t('screens.order.deliveryTime')} - ${this.deliveryTime}`
        : this.$t('screens.order.deliveryTimeWillBeUpdated');
      const seen = this.orderSeen;

      return {
        waiting_confirmation: {
          title: this.$t('screens.order.orderStatusPendingConfirmation', {
            orderId,
          }),
          intermediateIndex: seen ? 1 : 0,
          animationSrc: '/assets/order-status-animations/loading.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          deliveryTime,
          disclaimerText: this.$store.getters
            .customizationOrderInfoStatusPendingDisclaimerText,
        },
        waiting_others: {
          title: this.$t('screens.order.orderStatusWaitingOthers', {
            ready: this.guestPlacedOrdersCount,
            total: this.guestCount,
          }),
          intermediateIndex: 1,
          description: this.$t(
            'screens.order.orderStatusWaitingOthersDescription'
          ),
          showGuestCounter: true,
          animationOptions: {},
          animationSrc: null,
          minifyOrderScreenAfterSuccess: false,
        },
        sending_to_pos: {
          title: this.$t('screens.order.orderStatusSendingToPOSTitle', {
            orderId,
          }),
          description: this.$t(
            'screens.order.orderStatusSendingToPOSDescription'
          ),
          animationSrc: '/assets/order-status-animations/sending-to-pos.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          hideAnimationAfterSuccess: false,
          intermediateIndex: 2,
        },
        active: {
          title: this.$t('screens.order.orderStatusPreparing', {
            orderId,
          }),
          intermediateIndex: 2,
          animationSrc: '/assets/order-status-animations/preparing-food.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          deliveryTime,
          disclaimerText: this.$store.getters
            .customizationOrderInfoStatusPreparingDisclaimerText,
        },
        partially_delivered: {
          title: this.$t('screens.order.orderStatusPartiallyDelivered', {
            orderId,
          }),
          intermediateIndex: 4,
          animationSrc: '/assets/order-status-animations/preparing-food.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          deliveryTime,
          disclaimerText: this.$store.getters
            .customizationOrderInfoStatusPartialDeliveryDisclaimerText,
        },
        closed: {
          title: this.$t('screens.order.orderStatusDelivered', {
            orderId,
          }),
          animationSrc: '/assets/order-status-animations/order-delivered.json',
          animationOptions: {
            width: '150px',
          },
          hideAnimationAfterSuccess: true,
          disclaimerText: this.$store.getters
            .customizationOrderInfoStatusClosedDisclaimerText,
        },
      };
    },
    noStatusChangeStatusData() {
      const orderId = this.orderId;

      return {
        without_status_changes: {
          title: this.$t('screens.order.orderStatusReceived', {
            orderId,
          }),
          description: this.$t('screens.order.orderReceivedDescription'),
          animationSrc: '/assets/order-status-animations/order-delivered.json',
          animationOptions: {
            width: '150px',
          },
          hideAnimationAfterSuccess: true,
          intermediateIndex: undefined,
        },
        pick_up: {
          title: this.$t('screens.order.orderStatusReceived', {
            orderId,
          }),
          description: this.$t('screens.order.orderReceivedPickupDescription'),
          animationSrc: '/assets/order-status-animations/order-delivered.json',
          animationOptions: {
            width: '150px',
          },
          hideAnimationAfterSuccess: true,
          intermediateIndex: undefined,
        },
        pay_at_restaurant: {
          title: this.$t('screens.order.orderStatusReceived', {
            orderId,
          }),
          description: this.$t(
            'screens.order.orderReceivedPayAtRestaurantDescription'
          ),
          animationSrc: '/assets/order-status-animations/order-delivered.json',
          animationOptions: {
            width: '150px',
          },
          hideAnimationAfterSuccess: true,
          intermediateIndex: undefined,
        },
        waiting_others: {
          title: this.$t('screens.order.orderStatusWaitingOthers', {
            ready: this.guestPlacedOrdersCount,
            total: this.guestCount,
          }),
          intermediateIndex: 1,
          description: this.$t(
            'screens.order.orderStatusWaitingOthersDescription'
          ),
          showGuestCounter: true,
          animationOptions: {},
          animationSrc: null,
          minifyOrderScreenAfterSuccess: false,
        },
        sending_to_pos: {
          title: this.$t('screens.order.orderStatusSendingToPOSTitle', {
            orderId,
          }),
          description: this.$t(
            'screens.order.orderStatusSendingToPOSDescription'
          ),
          animationSrc: '/assets/order-status-animations/sending-to-pos.json',
          animationOptions: {
            loop: true,
            width: '150px',
          },
          hideAnimationAfterSuccess: false,
          intermediateIndex: 2,
        },
      };
    },
    statusData() {
      let status = this.status;

      const failed = this.status === 'failed';
      const rejected = this.status === 'rejected';

      const noStatusChanges = this.orderFlowIsWithoutStatusChanges;
      const fullOrderFlow = this.fullOrderFlow;

      const payAtRestaurant = this.payAtRestaurant;
      const pickup = this.tableTypeIsPickup;
      const partial = this.orderStatusPartiallyDelivered;
      const waitingOthers = !!this.orderWaitingOthers;
      const sendingToPosStatus = this.sendingToPos && !this.sentToPos;

      const errorStatusData = this.errorStatusData;

      if (failed || rejected) {
        return errorStatusData[status] || {};
      }

      // TODO - Refactor this logic further
      if (noStatusChanges) {
        if (waitingOthers) {
          status = 'waiting_others';
        } else if (sendingToPosStatus) {
          status = 'sending_to_pos';
        } else if (payAtRestaurant) {
          status = 'pay_at_restaurant';
        } else if (pickup) {
          status = 'pick_up';
        } else {
          status = 'without_status_changes';
        }
        const data = this.noStatusChangeStatusData[status] || {};

        if (data?.hideAnimationAfterSuccess) {
          this.hideAnimationAfterSuccess();
        }

        return data;
      } else if (pickup) {
        if (partial) {
          status = 'partially_delivered';
        }

        const data = this.pickUpStatusData[status] || {};

        if (data?.hideAnimationAfterSuccess) {
          this.hideAnimationAfterSuccess();
        }

        return data;
      } else if (fullOrderFlow) {
        if (partial) {
          status = 'partially_delivered';
        } else if (waitingOthers) {
          status = 'waiting_others';
        } else if (sendingToPosStatus) {
          status = 'sending_to_pos';
        }

        const data = this.fullOrderFlowStatusData[status] || {};

        if (data?.hideAnimationAfterSuccess) {
          this.hideAnimationAfterSuccess();
        }

        return data;
      }

      return {};
    },
  },
  methods: {
    hideAnimationAfterSuccess() {
      delay(() => {
        this.minifyOrderScreenAfterSuccess = true;
      }, 2600);
    },
  },
};
</script>

<style lang="scss" scoped>
.success-icon {
  width: 0;
  height: 28px;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out;

  &--show {
    width: 28px;
    margin-right: 8px;
  }
}

.status-indicators {
  gap: 20px;
}

.indicator {
  background: $color-gray-500;
}

.animation {
  min-height: 100px;
  transition: min-height 0.5s ease-in-out, height 0.5s ease-in-out,
    opacity 0.5s ease-in-out, padding 0.5s ease-in-out;

  &--hide {
    padding: 0 !important;
    height: 0;
    min-height: 0;
    opacity: 0;
  }
}

.status-image {
  max-width: 65%;
}
</style>
