export const tipChipsByPercentage = ({
  amount,
  formatPrice,
  preselectedIndex = 2,
  guestCount = 1,
  requiredTipsConfig = {},
  customTipPercentages,
}) => {
  const { guestCountThreshold, preselectedTipPercentage } = requiredTipsConfig;
  if (!amount) {
    return null;
  }

  if (amount < 1.8) {
    const tipAmounts = [0, 1, 2, 3, 5];

    return tipAmounts.map(amount => {
      return {
        value: amount,
        selected: false,
        label: formatPrice(amount),
      };
    });
  }

  if (guestCount >= guestCountThreshold) {
    const tipPercentages = customTipPercentages || [0.1, 0.15, 0.2];
    const withoutZeroTip = tipPercentages.filter(
      tipPercentage => tipPercentage >= 0.1
    );

    return withoutZeroTip.map(tipPercentage => {
      const tipAmount = (amount * tipPercentage).toFixed(1);

      return {
        value: tipAmount,
        selected: tipPercentage === preselectedTipPercentage,
        label: formatPrice(tipAmount),
        percentLabel: `${tipPercentage * 100}%`,
      };
    });
  }

  const tipPercentages = customTipPercentages || [0.05, 0.1, 0.15];
  const tipChips = tipPercentages.map(tipPercentage => {
    const tipAmount = (amount * tipPercentage).toFixed(1);

    return {
      value: tipAmount,
      label: formatPrice(tipAmount),
      percentLabel: `${tipPercentage * 100}%`,
    };
  });
  const noTip = {
    value: 0,
    selected: false,
    label: formatPrice(0),
  };

  const allChips = [noTip, ...tipChips];

  return allChips.map((chip, index) => {
    return {
      ...chip,
      selected: index === preselectedIndex,
    };
  });
};
