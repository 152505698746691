var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"layout-card",class:{
    'layout-card--header': _vm.type === `header`,
    'layout-card--rounded': _vm.rounded,
    'layout-card--header-top-sm-radius': _vm.type === `item-header`,
    'layout-card--shadow': _vm.shadow,
    'layout-card--primary': _vm.primary,
    'layout-card--hide': _vm.hideBackground,
  },style:(_vm.layoutCardCustomStyles),attrs:{"flat":"","height":_vm.grow ? '100%' : 'auto'}},[_c('div',{class:{
      container: !_vm.largerContainer,
      'container-24': _vm.largerContainer,
      'container-compact': _vm.compact,
      'grow-container': _vm.grow,
    }},[(_vm.loading)?_c('div',{staticClass:"py-6 d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1):_c('div',{class:{ 'layout-card--blur': _vm.blur, 'grow-inner-container': _vm.grow }},[(_vm.title)?_c('div',{staticClass:"d-flex justify-space-between align-center",class:{ 'pb-3': !_vm.collapsable },on:{"click":function($event){_vm.collapsable ? _vm.toggleCollapse() : () => {}}}},[_c('div',[(_vm.preheading)?_c('div',{staticClass:"tp-text-body grey--text"},[_vm._v(" "+_vm._s(_vm.preheading)+" ")]):_vm._e(),_c('div',{staticClass:"tp-text-subtitle"},[_vm._v(_vm._s(_vm.title))])]),(_vm.required)?_c('v-chip',{staticClass:"white--text",attrs:{"ripple":false,"color":"#9E9E9E","small":""}},[_vm._v(" "+_vm._s(_vm.$t('required'))+" ")]):_vm._e(),(_vm.collapsable)?_c('div',[_c('v-icon',[(!_vm.collapsed)?[_vm._v("mdi-chevron-up")]:[_vm._v("mdi-chevron-down")]],2)],1):_vm._e()],1):_vm._e(),(_vm.collapsable)?_c('v-expand-transition',[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.collapsed),expression:"!collapsed"}],attrs:{"no-gutters":""}},[_vm._t("default")],2)],1):_vm._t("default")],2),_vm._t("actions")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }