<template>
  <img
    v-if="customLoyaltyCardIcon"
    style="width: 16px;"
    :src="customLoyaltyCardIcon"
  />
  <v-icon v-else small :color="'black'">
    mdi-credit-card-fast-outline
  </v-icon>
</template>

<script>
export default {
  computed: {
    customLoyaltyCardIcon() {
      return this.$store.getters.customizationLoyaltyCardIcon;
    },
  },
};
</script>

<style></style>
