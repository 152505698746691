<template>
  <div
    v-if="showMadeInLithuania"
    class=" d-flex mt-auto pt-6 pb-4 px-6 align-end"
  >
    <GreetLogo :width="55" :height="14" color="black" />
    <p class="tp-text-caption-dark text-center justify-center mb-0">
      <span> {{ $t('generic.madeInLithuania') }} </span>
    </p>
  </div>
  <div v-else class="mt-auto pt-6 pb-4 px-6"></div>
</template>

<script>
import GreetLogo from '@/components/GreetLogo.vue';

export default {
  name: 'MadeInLithuania',
  components: {
    GreetLogo,
  },
  computed: {
    showMadeInLithuania() {
      const restaurantLocale = this.$store.getters.restaurantCountryCode;

      return restaurantLocale.toLowerCase() === 'lt';
    },
  },
};
</script>
