<template>
  <div>
    <div
      v-if="tipCardNotAvailable"
      :style="{ height: '100vh' }"
      :data-cy="`tip-personnel-member-empty-state`"
      class="d-flex flex-column justify-center align-center white"
    >
      <StateScreen
        :title="$t('screens.tipPersonnelMember.empty.title')"
        :subtitle="$t('screens.tipPersonnelMember.empty.subtitle')"
        :image="require(`@/assets/images/states/sad-mascot.png`)"
      />
      <div class="d-flex pt-10 px-10 w-100">
        <Button dark @click="refreshPage">
          {{ $t('screens.tipPersonnelMember.empty.cta') }}
        </Button>
      </div>
    </div>
    <div v-else>
      <div
        v-if="isLoading"
        :style="{ height: '100vh' }"
        class="py-6 d-flex justify-center align-center"
      >
        <v-progress-circular indeterminate color="black" />
      </div>
      <v-banner
        v-model="showSpecialBanner"
        single-line
        transition="slide-y-transition"
        color="#1f243c"
      >
        <div
          class="d-flex tp-text-subtitle-semibold-white justify-center align-center font-weight-medium"
        >
          {{ $t('kovo11') }}
        </div>
      </v-banner>
      <form v-show="!isLoading" id="payment-form" @submit="payByCard">
        <LayoutCard v-if="tipsRecipient" style="border-radius: 0 0 20px 20px;">
          <TipsHeader :color="tipsColorGlobal" />
          <div v-if="!orderId" class="top-buttons">
            <v-btn
              class="lang-button"
              icon
              :ripple="false"
              data-cy="language-selector-button"
              @click="openLanguageSelector"
            >
              <img :src="languageImage" style="width: 22px" />
            </v-btn>
          </div>
          <EasyWithGreet
            style="position: absolute; top: 26px; left: 50%; transform: translate(-50%, -50%);"
          />
          <div class="d-flex flex-row justify-end pb-6">
            <v-icon v-if="!!orderId" @click="goBack">
              mdi-close
            </v-icon>
          </div>
          <div class="d-flex flex-column align-center text-center mt-5">
            <Avatar
              :photo-url="tipsRecipient.photo"
              :name="tipsRecipient.name"
              :lazy-src="tipsRecipient.lazySrc"
              data-cy="personnel-member-avatar"
              :size="100"
            />
            <h2 class="pt-3" data-cy="personnel-member-name">
              {{ tipsRecipient.name }}
            </h2>
            <div
              class="tp-text-body pt-3"
              data-cy="personnel-member-description"
            >
              {{ tipsRecipient.description }}
            </div>
            <PersonnelBottomSheetPicker
              :personnel-list="personnelList"
              :default-open="isStaffMembersSelectMode"
              @pick="pickPersonnel"
            />
            <ReviewDrawer
              v-if="tipsRecipient.show_review_cta_in_payment_screen"
              :tips-qr-card-hash="tipsQrCardHash"
            >
              <template v-slot:activator="{ toggle }">
                <a
                  class="primary--text"
                  data-cy="leave-review-cta"
                  @click="toggle"
                >
                  {{ $t('components.review.leaveReviewCta') }}
                </a>
              </template>
            </ReviewDrawer>
          </div>
          <h4 class="pt-10 pb-3 text-center" data-cy="tip-title">
            {{ $t('screens.tipPersonnelMember.tipTitle') }}
          </h4>
          <TipsPicker
            v-if="tipsRecipient.name"
            :tips="tipChips"
            :color="tipsColorGlobal"
            :min="minTipAmount"
            :max="maxTipAmount"
            @on-change="onTipsChange"
          />
          <div class="py-8">
            <div v-if="tipAmount" class="d-flex justify-space-between pb-2">
              <span class="tp-text-body">
                {{
                  this.$t('screens.tipPersonnelMember.subtotal', {
                    name: tipsRecipient.name,
                  })
                }}
              </span>
              <span class="tp-text-body">
                {{ tipAmountFormatted }}
              </span>
            </div>
            <div
              v-if="transactionFee"
              class="d-flex justify-space-between pb-2"
            >
              <span class="tp-text-body" data-cy="tip-transaction-fee">
                {{ this.$t('screens.tipPersonnelMember.transactionFee') }}
              </span>
              <span class="tp-text-body" data-cy="tip-transaction-fee-amount">
                {{ feeFormatted }}
              </span>
            </div>
            <div class="d-flex justify-space-between">
              <span class="tp-text-subtitle font-weight-medium">
                {{ this.$t('screens.tipPersonnelMember.total') }}
              </span>
              <span
                class="tp-text-subtitle font-weight-medium"
                data-cy="tip-total-amount"
              >
                {{ totalFormatted }}
              </span>
            </div>
          </div>
          <v-expand-transition>
            <div v-show="tipAmount">
              <PaymentMethods
                ref="paymentMethods"
                montonio-disabled
                :color="tipsColorGlobal"
                :wallet-available="walletAvailable"
                :payment-methods-override="paymentMethodsOverride"
                @on-change="onPaymentMethodChange"
              />
            </div>
          </v-expand-transition>
        </LayoutCard>
        <div class="px-4 pb-16">
          <v-alert
            v-show="errorMessage && tipAmount"
            border="left"
            colored-border
            color="red accent-4"
            class="mt-7"
            dismissible
            close-icon="mdi-close"
            >{{ errorMessage }}</v-alert
          >
          <v-expand-transition>
            <div v-show="showPayCta && tipAmount" class="pt-3 px-6">
              <Button
                ref="CTAButton"
                block
                dark
                :disabled="isLoadingPayments"
                type="submit"
                data-cy="tip-cta"
              >
                {{ $t('screens.tipPersonnelMember.payCta') }}
              </Button>
            </div>
          </v-expand-transition>
        </div>
      </form>
      <PaymentStatusBackdrop ref="backdrop" />
      <v-overlay v-show="!isLoading" :value="updatingTipAmount">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <LocaleSelector
      ref="languageSelector"
      :language-list="languageList"
      :selected-language="this.$i18n.locale()"
      @changeLanguage="changeLanguage"
    />
    <PaymentBottomSheetError ref="paymentBottomSheetError" />
  </div>
</template>

<script>
import LayoutCard from '@/components/LayoutCard.vue';
import TipsPicker from '@/components/Cart/TipsPicker.vue';
import PaymentMethods from '@/components/Payments/PaymentMethods.vue';
import Button from '@/components/Button.vue';
import PaymentStatusBackdrop from '@/components/Payments/PaymentStatusBackdrop.vue';
import Avatar from '@/components/common/Avatar.vue';
import StateScreen from '@/components/StateScreen.vue';
import EasyWithGreet from '@/components/EasyWithGreet.vue';
import TipsHeader from '@/components/Tips/TipsHeader.vue';
import LocaleSelector from '@/components/AgnosticLocaleSelector.vue';
import PersonnelBottomSheetPicker from '@/components/Tips/PersonnelBottomSheetPicker.vue';
import ReviewDrawer from '@/components/Reviews/ReviewDrawer.vue';
import PaymentBottomSheetError from '@/components/Payments/PaymentBottomSheetError.vue';
import { compact } from 'lodash';

import { PROVIDERS } from '../utils/constants';

export default {
  components: {
    TipsPicker,
    LayoutCard,
    PaymentMethods,
    Button,
    PaymentStatusBackdrop,
    Avatar,
    StateScreen,
    EasyWithGreet,
    TipsHeader,
    LocaleSelector,
    PersonnelBottomSheetPicker,
    ReviewDrawer,
    PaymentBottomSheetError,
  },
  data() {
    return {
      isLoading: true,
      isLoadingPayments: false,
      stripe: undefined,
      tipAmount: null,
      paymentMethod: null,
      walletAvailable: false,
      errorMessage: null,
      paymentRequest: null,
      elements: null,
      tipsQrCardHash: null,
      orderId: null,
      sessionToken: null,
      total: 0,
      transactionFee: 0,
      updatingTipAmount: false,
      neopayPaymentIntent: null,
      showSpecialBanner: false,
      stripePaymentUuid: null,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    paymentMethodsOverride() {
      return [PROVIDERS.STRIPE, PROVIDERS.NEOPAY];
    },
    tipsRecipient() {
      return this.$store.getters.tipsRecipientData;
    },
    totalFormatted() {
      return this.$store.getters.getFormattedPrice(this.total);
    },
    feeFormatted() {
      return this.$store.getters.getFormattedPrice(this.transactionFee);
    },
    tipAmountFormatted() {
      return this.$store.getters.getFormattedPrice(this.tipAmount);
    },
    minTipAmount() {
      return this.$store.getters.getMinAmount;
    },
    maxTipAmount() {
      return this.$store.getters.getMaxAmount;
    },
    tipAmounts() {
      return this.$store.getters.getTipsAmounts || [];
    },
    preselectedTipIndex() {
      return this.$store.getters.getPreselectedTipIndex || 0;
    },
    tipChips() {
      const formatPrice = amount => {
        return this.$store.getters.getFormattedPrice(amount, 0);
      };

      return this.tipAmounts.map((amount, index) => {
        return {
          value: amount,
          selected: index === this.preselectedTipIndex,
          label: formatPrice(amount),
        };
      });
    },
    showPayCta() {
      return (
        this.paymentMethod === PROVIDERS.STRIPE ||
        this.paymentMethod === PROVIDERS.NEOPAY
      );
    },
    languageImage() {
      const code = this.$i18n.locale();

      return require(`@/assets/images/flags/${code}.svg`);
    },
    locale() {
      return this.$i18n.locale();
    },
    waiterLocale() {
      return this.$store.getters.getWaiterLocale;
    },
    languageList() {
      // Hardcoded for now
      const originalWaiterLocale = this.waiterLocale;
      const languages = ['lt', 'en'];

      return compact([originalWaiterLocale, ...languages]);
    },
    personnelList() {
      return this.$store.getters.getPersonnelList;
    },
    selectedPersonnelId() {
      return this.$store.getters.getSelectedPersonnelId;
    },
    isStaffMembersSelectMode() {
      return this.$store.getters.getIsStaffMembersSelectMode;
    },
    tipCardNotAvailable() {
      return this.$store.getters.getTipCardNotAvailable;
    },
  },
  watch: {
    async tipAmount(newTipAmount, oldTipAmount) {
      if (oldTipAmount !== newTipAmount && !!newTipAmount) {
        this.generatePaymentIntents(newTipAmount);
      }
    },
    async selectedPersonnelId(newPersonnelId, oldPersonnelId) {
      if (newPersonnelId !== oldPersonnelId) {
        this.generatePaymentIntents(this.tipAmount);
      }
    },
  },
  async mounted() {
    this.isLoading = true;
    const tipsQrCardHash = this.$route.params.tipsQrCardHash;
    const orderId = this.$route.query.orderId;
    const sessionToken = this.$route.query.sessionToken;

    this.tipsQrCardHash = tipsQrCardHash;
    this.orderId = orderId;
    this.sessionToken = sessionToken;

    if (sessionToken) {
      await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    }

    await this.$store.dispatch('fetchTipsData', { tipsQrCardHash });

    await this.loadStripeIfNeeded();
    this.isLoading = false;

    // show banner of March 11th
    if (this.$moment().isSame('2024-03-11', 'day')) {
      this.showSpecialBanner = true;
    }
  },
  methods: {
    async generatePaymentIntents(newTipAmount) {
      if (!newTipAmount) {
        return;
      }

      this.updatingTipAmount = true;
      await this.loadStripeIfNeeded();

      const [paymentRequestResponse, neoPaymentResponse] = await Promise.all([
        this.generatePaymentIntent(newTipAmount),
        this.createNeopayTransaction({ amount: newTipAmount }),
      ]);

      this.neopayPaymentIntent = neoPaymentResponse;

      this.updatingTipAmount = false;
      const { clientSecret } = paymentRequestResponse;

      const options = {
        clientSecret,
        locale: this.$i18n.locale() || 'LT',
        appearance: {
          theme: 'flat',
          variables: {
            fontFamily: ' "Poppins", sans-serif',
            fontLineHeight: '1.5',
            borderRadius: '10px',
            colorBackground: '#F6F8FA',
            colorPrimaryText: '#262626',
            colorPrimary: this.tipsColorGlobal,
          },
        },
      };

      this.total = paymentRequestResponse.total;
      this.transactionFee = paymentRequestResponse.transaction_fee;
      this.stripePaymentUuid = paymentRequestResponse.uuid;

      this.elements = this.stripe.elements(options);
      this.paymentRequest = this.stripe.paymentRequest({
        country: 'LT',
        currency: 'eur',
        total: {
          label: this.tipsRecipient.name,
          amount: Math.round(this.total * 100),
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });

      const paymentElementOptions = {
        wallets: {
          applePay: 'never',
          googlePay: 'never',
        },
      };

      const card = this.elements.create('payment', paymentElementOptions);
      const prButton = this.elements.create('paymentRequestButton', {
        paymentRequest: this.paymentRequest,
      });

      const result = await this.paymentRequest.canMakePayment();

      if (this.$refs?.paymentMethods?.$refs?.card) {
        try {
          card.mount(this.$refs.paymentMethods.$refs.card);
        } catch (error) {
          this.payByCardDisabled = true;
        }
      }

      if (result && this.$refs?.paymentMethods?.$refs?.prButton) {
        prButton.mount(this.$refs.paymentMethods.$refs.prButton);
        this.walletAvailable = true;
      } else {
        this.walletAvailable = false;
        console.log('Cannot make payment');
      }

      this.paymentRequest.on('paymentmethod', async e => {
        const {
          paymentIntent,
          error: confirmError,
        } = await this.stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          {
            handleActions: false,
          }
        );

        if (confirmError) {
          e.complete('fail');
          this?.$refs?.paymentBottomSheetError?.open(confirmError);
          return;
        } else {
          e.complete('success');
          if (paymentIntent && paymentIntent.status === 'requires_action') {
            const { error } = await this.stripe.confirmCardPayment(
              clientSecret
            );
            if (error) {
              console.log(error);
              this.walletAvailable = false;
              this?.$refs?.paymentBottomSheetError?.open(error);
            } else {
              this.goToSuccess(clientSecret);
            }
          } else {
            this.goToSuccess(clientSecret);
          }
        }
      });
    },
    async generatePaymentIntent(newTipAmount) {
      return this.$store.dispatch('generatePaymentIntent', {
        tipsQrCardHash: this.tipsQrCardHash,
        amount: newTipAmount,
      });
    },
    async createNeopayTransaction({ amount = null } = {}) {
      return this.$store.dispatch('generateNeoPaymentIntent', {
        amount,
        tipsQrCardHash: this.tipsQrCardHash,
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    refreshPage() {
      window.location.reload();
    },
    async onTipsChange(amount) {
      this.tipAmount = amount;
    },
    async goToSuccess(clientSecret) {
      await this.showBackdrop('success', 2000);
      this.$router.replace({
        name: 'TipSuccess',
        params: {
          tipsQrCardHash: this.tipsQrCardHash,
        },
        query: {
          payment_intent_client_secret: clientSecret,
          orderId: this.orderId,
          sessionToken: this.sessionToken,
          personnel_id: this.selectedPersonnelId,
          paymentUuid: this.stripePaymentUuid,
        },
      });
    },
    onPaymentMethodChange(method) {
      // This is not stripe but card payment method
      if (method === 'stripe') {
        setTimeout(this.scrollToPayCTA, 300);
      } else if (method === PROVIDERS.NEOPAY) {
        setTimeout(this.scrollToPayCTA, 300);
        this.transactionFee = this.neopayPaymentIntent.transaction_fee;
        this.total = this.neopayPaymentIntent.total;
      }

      this.paymentMethod = method;
    },
    async loadStripeIfNeeded() {
      if (!this.stripe) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PK || '');
      }
    },
    async payByCard(e) {
      e.preventDefault();

      if (this.isLoadingPayments) {
        return;
      }

      this.isLoadingPayments = true;

      if (this.paymentMethod === PROVIDERS.NEOPAY) {
        await this.payByNeopay(e);
        return;
      }

      this.showBackdrop('loading');

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: `${process.env.VUE_APP_BASE_URL}/tip/success/${this.tipsQrCardHash}?orderId=${this.orderId}&sessionToken=${this.sessionToken}&personnel_id=${this.selectedPersonnelId}&paymentUuid=${this.stripePaymentUuid}`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        this?.$refs?.paymentBottomSheetError?.open(error);
        this.errorMessage = error.message;
        setTimeout(this.scrollToPayCTA, 300);
      } else {
        this?.$refs?.paymentBottomSheetError?.open(error);
        console.error('An unexpected error occurred.');
      }

      this.isLoadingPayments = false;
      this.hideBackdrop();
    },
    payByNeopay() {
      if (this.neopayPaymentIntent.redirect_url) {
        this.isLoading = true;
        window.location = this.neopayPaymentIntent.redirect_url;
      }
    },
    async scrollToPayCTA() {
      await new Promise(resolve => setTimeout(resolve, 100));
      this.$refs.CTAButton.$el.scrollIntoView({ behavior: 'smooth' });
    },
    async showBackdrop(type, duration) {
      await this.$refs.backdrop.show(type, duration);
    },
    async hideBackdrop() {
      await this.$refs.backdrop.hide();
    },
    openLanguageSelector() {
      this.$refs.languageSelector.open();
    },
    async changeLanguage(language) {
      const translations = (await import(`../i18n/${language.code}.js`))
        .default;

      this.$i18n.add(language.code, translations);
      this.$i18n.set(language.code);
      this.$store.dispatch('setSelectedLanguage', language.code);
    },
    pickPersonnel(recipientId) {
      this.$store.dispatch('setTipsRecipient', { recipientId });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-buttons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 100;
}

.lang-button {
  background-color: rgb(255 255 255 / 70%) !important;
  box-shadow: none !important;
  transition: background-color 100ms ease-in-out;
}
</style>
