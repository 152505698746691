<template>
  <LayoutCard
    v-if="displayBlock"
    rounded
    data-cy="loyalty-program-enrollment"
    :title="loyaltyState.hideTitle ? null : $t(title)"
  >
    <v-expand-transition>
      <div v-if="discountApplied" class="d-flex justify-space-between">
        <span class="tp-text-body d-flex align-center">
          {{ $t(loyaltyState.content) }}
          <LoyaltyCardPictogram class="mx-2" />
        </span>
        <span class="tp-text-subtitle font-weight-bold">
          {{ $t('screens.order.loyalty.activatedLabel') }}
        </span>
      </div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-if="!discountApplied">
        <div
          class="d-flex flex-row  justify-space-between"
          @click="loyaltyState.onClick"
        >
          <div class="d-flex">
            <LoyaltyCard
              v-if="restaurantLoyaltyProgram"
              :small="true"
              :loyalty-program="restaurantLoyaltyProgram"
              width="64px"
              height="41px"
            />
            <div class="tp-text-body-gray d-flex align-center ml-3">
              {{
                $t(loyaltyState.content, {
                  restaurantName,
                  loyaltyProgramName,
                })
              }}
            </div>
          </div>
          <v-icon
            v-show="loyaltyState.icon"
            class="ml-2"
            :color="loyaltyState.iconColor"
          >
            {{ loyaltyState.icon }}
          </v-icon>
        </div>
        <Transition name="opacity">
          <div v-show="loyaltyState.showApplyButton">
            <Button
              small
              block
              dark
              class="mt-3"
              data-cy="apply-loyalty-button"
              :disabled="loyaltyState.applyButtonLoading"
              @click="applyDiscount"
            >
              <span v-if="!loyaltyState.applyButtonLoading">
                {{ $t('screens.order.loyalty.registerToSaveButton') }}
              </span>
              <div
                v-if="loyaltyState.applyButtonLoading"
                class="d-flex align-center"
              >
                <v-progress-circular
                  indeterminate
                  :size="15"
                  :width="2"
                  color="white"
                  class="mr-2"
                />
                {{ loaderContent }}
              </div>
            </Button>
          </div>
        </Transition>
      </div>
    </v-expand-transition>
  </LayoutCard>
</template>

<script>
import Button from '@/components/Button.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';
import LoyaltyCardPictogram from '@/components/Loyalty/LoyaltyCardPictogram.vue';

export default {
  components: {
    LoyaltyCard,
    LayoutCard,
    Button,
    LoyaltyCardPictogram,
  },
  props: {
    redirect: {
      type: String,
      default: 'PlaceOrder',
    },
    title: {
      type: String,
      default: 'screens.placeOrder.registerToSaveTitle',
    },
    uniqueOrderNumber: {
      type: String,
      required: false,
      default: null,
    },
    loyaltyDiscountAmount: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      applyFailed: false,
      loaderState: 'SENDING',
      applied: false,
    };
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    userEnrolled() {
      return this.$store.getters.enrolledRestaurantLoyaltyProgram;
    },
    restaurantLoyaltyProgram() {
      return this.$store.getters.restaurantLoyaltyProgram();
    },
    loyaltyProgramEnabled() {
      return this.$store.getters.functionalityLoyaltyProgramEnabled;
    },
    currentRestaurantId() {
      return this.$store.getters.getCurrentRestaurantId;
    },
    displayBlock() {
      return this.loyaltyProgramEnabled && this.restaurantLoyaltyProgram;
    },
    restaurantName() {
      return this.$store.state.restaurant?.name;
    },
    loyaltyProgramName() {
      return this.restaurantLoyaltyProgram?.name;
    },
    discountApplied() {
      return (
        this.applied ||
        this.$store.getters.discountApplied(this.uniqueOrderNumber)
      );
    },
    applyingDiscount() {
      return this.$store.getters.getApplyingLoyaltyDiscountToOrder;
    },
    loyaltyState() {
      const LOYALTY_STATE = {
        ENROLLED: {
          content: 'screens.order.loyalty.applyToSaveContent',
          showApplyButton: true,
          icon: null,
          onClick: () => {},
        },
        NOT_ENROLLED: {
          content: 'screens.placeOrder.registerToSaveContent',
          showApplyButton: false,
          icon: 'mdi-chevron-right',
          onClick: this.navigateToRegister,
        },
        APPLYING: {
          content: 'screens.order.loyalty.applyToSaveContent',
          showApplyButton: true,
          applyButtonLoading: true,
          icon: null,
          onClick: () => {},
        },
        APPLY_SUCCESS: {
          hideTitle: true,
          content: 'screens.order.loyalty.discountApplied',
          showApplyButton: false,
          icon: 'mdi-check-circle',
          iconColor: 'green',
          onClick: () => {},
          applied: true,
        },
      };

      if (this.applyingDiscount && !this.discountApplied) {
        return LOYALTY_STATE.APPLYING;
      } else if (this.discountApplied) {
        return LOYALTY_STATE.APPLY_SUCCESS;
      } else if (this.userEnrolled) {
        return LOYALTY_STATE.ENROLLED;
      } else {
        return LOYALTY_STATE.NOT_ENROLLED;
      }
    },
    loaderContent() {
      const LOADER_CONTENT = {
        SENDING: this.$t('screens.order.loyalty.loader.sending'),
        APPLYING: this.$t('screens.order.loyalty.loader.applying'),
        GETTING: this.$t('screens.order.loyalty.loader.getting'),
      };

      return this.$t(LOADER_CONTENT[this.loaderState]);
    },
  },
  mounted() {
    if (this.displayBlock) {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'app_pay-go_has-loyalty',
      });
    }
  },
  methods: {
    navigateToRegister: function() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'app_pay-bill_loyalty-program_register-button-click',
      });

      this.$router.push({
        name: 'Register',
        params: {
          session: this.session,
        },
        query: {
          redirect: this.redirect,
        },
      });
    },
    async applyDiscount() {
      this.$store.dispatch('sendAnalyticsEvent', {
        event: 'app_pay-bill_loyalty-program_apply-discount-button-click',
      });
      this.startLoader();
      await this.$store.dispatch('applyLoyaltyDiscountToOrder', {
        uniqueOrderNumber: this.uniqueOrderNumber,
        loyaltyId: this.userEnrolled.id,
        onSuccess: ({ discountAmount, discountAmountFormatted }) => {
          // mark as applied. To avoid multiple jittering
          this.applied = true;
          setTimeout(() => {
            const title = this.$t('screens.order.loyalty.success.title');
            const description = discountAmount
              ? this.$t('screens.order.loyalty.success.description', {
                  discountAmount: discountAmountFormatted,
                })
              : undefined;

            this.$store.dispatch('showToastInstantly', { title, description });
          }, 300);
        },
        onError: error => {
          this.applyFailed = true;
          this.applied = false;
          this.$store.dispatch('sendAnalyticsEvent', {
            event: 'app_pay-bill_loyalty-program_apply-discount-error',
            error: error.message,
          });

          const ERRORS = {
            'No loyalty program is applicable. Cannot apply discount.': {
              description:
                'screens.order.loyalty.error.noApplicableProgramDescription',
            },
            'POS error. Loyalty card cannot be applied to external order': {
              description: 'screens.order.loyalty.error.posErrorDescription',
            },
            'Someone else is currently applying the discount. Cannot apply discount.': {
              description:
                'screens.order.loyalty.error.alreadyApplyingDescription',
            },
            'Discount is already applied in POS. Cannot apply discount.': {
              description:
                'screens.order.loyalty.error.alreadyAppliedDescription',
            },
            'Order items are selected. Cannot apply discount': {
              description:
                'screens.order.loyalty.error.itemsSelectedDescription',
            },
            'Loyalty card already applied to order': {
              description:
                'screens.order.loyalty.error.alreadyAppliedDescription',
            },
            GENERIC: {
              title: 'screens.order.loyalty.error.genericTitle',
              description: 'screens.order.loyalty.error.genericDescription',
            },
          };

          const title = this.$t(ERRORS.GENERIC.title);
          const description = ERRORS[error.message]
            ? this.$t(ERRORS[error.message].description)
            : this.$t(ERRORS.GENERIC.description);

          this.$store.dispatch('showToastInstantly', {
            title,
            description,
            isError: true,
          });
        },
      });
    },
    startLoader() {
      this.loaderState = 'SENDING';
      setTimeout(() => {
        this.loaderState = 'APPLYING';
      }, 2000);
      setTimeout(() => {
        this.loaderState = 'GETTING';
      }, 3000);
    },
  },
};
</script>
