<template>
  <div>
    <TopMenu :scroll-threshold="190">
      <v-toolbar-title class="d-flex align-center">
        <div class="d-flex align-end">
          <span class="tp-text-body-2">
            {{ $t('generic.easyWith') }}
          </span>
          <GreetLogo :width="55" :height="14" color="#E85941"></GreetLogo>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon :ripple="false" @click="openLanguageSelector">
        <img :src="languageImage" style="width: 22px" />
      </v-btn>
    </TopMenu>
    <div class="top-buttons">
      <v-btn
        class="lang-button"
        icon
        :ripple="false"
        @click="openLanguageSelector"
      >
        <img :src="languageImage" style="width: 22px" />
      </v-btn>
    </div>
    <v-img
      id="food-court-header-image"
      :src="foodCourt.cover"
      max-height="190px"
      min-height="190px"
      aspect-ratio="1"
      transition="fade-transition"
    >
      <template v-slot:placeholder>
        <v-skeleton-loader type="image" max-height="190px" />
      </template>
    </v-img>
    <v-container class="card-container">
      <v-row v-show="restaurantsLoading" dense>
        <v-col v-for="n in 6" :key="n" cols="6">
          <v-skeleton-loader type="card" class="card-skeleton" />
        </v-col>
      </v-row>
      <v-row dense style="margin-top: 0 !important;">
        <v-col
          v-for="(card, key) in restaurantCards"
          :key="card.id"
          :cols="card.flex"
          :data-cy="`food-court-${key}`"
        >
          <v-card
            flat
            rounded="lg"
            height="100%"
            :disabled="card.closed"
            :loading="openingRestaurantId === card.key"
            class="custom-disabled"
            @click="openRestaurant(card)"
          >
            <template v-slot:progress>
              <v-progress-linear
                color="black"
                height="2"
                indeterminate
              ></v-progress-linear>
            </template>
            <v-img
              :src="card.src"
              :lazy-src="card.lazySrc"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.1)"
              height="115px"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader type="image" height="115px" />
              </template>
            </v-img>
            <v-chip
              v-if="card.rushMode"
              small
              color="status-chip amber lighten-2 font-weight-medium"
            >
              <v-icon left>mdi-clock-fast</v-icon>
              {{ card.waitMinutes }}
            </v-chip>
            <v-chip
              v-if="card.closed"
              small
              color="status-chip red lighten-1 font-weight-medium"
            >
              <v-icon left>mdi-sleep</v-icon>
              {{ $t('foodCourt.restaurantList.closed') }}
            </v-chip>
            <v-card-title class="tp-text-subtitle-semibold title-container">
              {{ $t(card.title) }}
            </v-card-title>
            <v-divider class="mx-2"></v-divider>
            <v-card-actions class="tp-text-label-2">
              {{ $t(card.description) }}
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-fab-transition>
      <div class="orders-fab">
        <Button dark data-cy="food-court-CTA" @click="openActiveOrders">
          {{ $t('foodCourt.restaurantList.cta') }}
          <v-spacer></v-spacer>
          <div
            class="px-1 py-1 white rounded-circle d-inline-block black--text"
          >
            <div style="height: 15px; width: 15px">{{ activeOrdersCount }}</div>
          </div>
        </Button>
      </div>
    </v-fab-transition>
    <LocaleSelector
      ref="languageSelector"
      :language-list="languageList"
      :selected-language="this.$i18n.locale()"
      @changeLanguage="changeLanguage"
    />
  </div>
</template>

<script>
import LocaleSelector from '@/components/AgnosticLocaleSelector.vue';
import Button from '@/components/Button.vue';
import TopMenu from '@/components/Navigation/CollapsingTopMenu.vue';
import GreetLogo from '@/components/GreetLogo.vue';
import { defaultTheme } from '../../utils/themeDefaults';
import { seedShuffle } from '../../utils/functions';

export default {
  components: {
    LocaleSelector,
    Button,
    TopMenu,
    GreetLogo,
  },
  data() {
    return {
      languageSelectorOpen: false,
      openingRestaurantId: null,
    };
  },
  computed: {
    foodCourtDetailsLoading() {
      return this.$store.getters.getFoodCourtLoading;
    },
    foodCourt() {
      return this.$store.getters.getFoodCourtDetails;
    },
    restaurantsLoading() {
      return (
        this.$store.getters.getRestaurantsLoading ||
        this.foodCourtDetailsLoading
      );
    },
    restaurants() {
      const restaurants = this.$store.getters.getFoodCourtRestaurants;
      const foodCourtSession = this.$store.getters.getFoodCourtSession;

      return seedShuffle(restaurants, foodCourtSession);
    },
    restaurantCards() {
      return this.restaurants.map(restaurant => {
        const statusDisabled =
          restaurant?.order_status === 'temporarily_disabled';
        const notWorkingHours = restaurant.is_currently_working_hours === false;
        return {
          title: restaurant?.name,
          src: restaurant?.cover,
          lazySrc: restaurant?.cover_thumbnail,
          key: restaurant?.id,
          description: restaurant?.short_description,
          flex: 6,
          rushMode: restaurant?.order_status === 'rush_mode',
          closed: statusDisabled || notWorkingHours,
          waitMinutes: `${restaurant?.rush_mode_wait_minutes} min`,
        };
      });
    },
    languageImage() {
      const code = this.$i18n.locale();

      return require(`@/assets/images/flags/${code}.svg`);
    },
    languageList() {
      const languages = this.$store.getters.getFoodCourtLanguages || [];
      const defaultLanguage = this.$store.getters.getFoodCourtDefaultLanguage;

      return [...languages, defaultLanguage] || [];
    },
    defaultLanguage() {
      return this.$store.getters.getFoodCourtLanguage;
    },
    activeOrdersCount() {
      return this.$store.getters.getActiveOrdersCount;
    },
  },
  mounted() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}`);

    const hash = this.$route.params.foodCourtHash;

    this.$store.dispatch('setFoodCourtHash', { hash });
    this.$store.dispatch('fetchFoodCourtRestaurants', { hash });
  },
  methods: {
    openLanguageSelector() {
      this.$refs.languageSelector.open();
    },
    async changeLanguage(language) {
      const hash = this.$route.params.foodCourtHash;

      const translations = (await import(`../../i18n/${language.code}.js`))
        .default;

      this.$i18n.add(language.code, translations);
      this.$i18n.set(language.code);

      this.$store.dispatch('fetchFoodCourtTranslations', {
        hash,
        languageCode: language.code,
      });
      this.$store.dispatch('setSelectedLanguage', language.code);
      this.$store.dispatch('updateFoodCourtSessionLanguage', {
        languageCode: language.code,
      });
    },
    openActiveOrders() {
      const foodCourtSession = this.$store.getters.getFoodCourtSession;

      this.$router.push({
        name: 'FoodCourtOrders',
        params: {
          foodCourtSession,
        },
      });
    },
    resetDefaultStyles() {
      defaultTheme.forEach(theme => {
        document.documentElement.style.setProperty(theme.property, theme.value);
      });
    },
    async openRestaurant(restaurant) {
      this.openingRestaurantId = restaurant.key;
      const restaurantId = restaurant.key;
      const hash = this.$route.params.foodCourtHash;

      this.resetDefaultStyles();

      const onSuccess = session => {
        this.openingRestaurantId = null;
        this.$store.dispatch('resetRestaurantId');
        this.$store.dispatch('setTipAmount', null);
        this.$router.push({
          name: 'Menu',
          params: { session },
          query: { foodCourt: hash },
        });
      };

      this.$store.dispatch('fetchRestaurantSession', {
        restaurantId,
        onSuccess,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  padding-bottom: 120px;
}

.top-buttons {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  z-index: 100;
}

.lang-button {
  background-color: rgb(255 255 255 / 70%) !important;
  box-shadow: none !important;
  transition: background-color 100ms ease-in-out;
}

.title-container {
  padding: 8px !important;
  line-height: normal !important;
}

.orders-fab {
  position: fixed;
  bottom: 12px;
  padding: 12px;
  width: 100%;
  z-index: 100;

  @media screen and (min-width: $max-app-width) {
    max-width: $max-app-width;
  }
}

.card-skeleton::v-deep {
  /* padding: 12px !important; */
  .v-skeleton-loader__image {
    height: 115px !important;
  }
}

.status-chip {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 1 !important;
}
</style>
