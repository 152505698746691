<template>
  <div class="program-details-container">
    <NavHeader
      :title="$t('screens.paymentHistory.navTitle')"
      @onBackClick="goBack"
    />
    <div v-if="loading" class="d-flex justify-center py-6">
      <v-progress-circular indeterminate color="black" />
    </div>
    <LayoutCard v-else rounded>
      <StateScreen
        v-if="payments.length === 0"
        :title="$t('screens.paymentHistory.empty.title')"
        :subtitle="$t('screens.paymentHistory.empty.content')"
        :image="require(`@/assets/images/states/empty.svg`)"
      />
      <div v-else class="text-center">
        <div
          v-for="(payment, i) in payments"
          :key="i"
          @click="navigateToPaymentDetails(payment)"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex flex-column align-start">
              <span class="tp-text-subtitle-medium">
                {{ restaurantName }}
              </span>
              <span class="tp-text-body grey--text text--darken-2">
                {{ new Date(payment.paid_at).toLocaleString() }}
              </span>
            </div>
            <div class="d-flex align-center">
              <div class="tp-text-body align-center grey--text text--darken-2">
                {{ $store.getters.getFormattedPrice(payment.total) }}
              </div>
              <v-icon class="ml-2">
                mdi-chevron-right
              </v-icon>
            </div>
          </div>
          <v-divider v-if="i !== payments.length - 1" class="my-4" />
        </div>
      </div>
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import StateScreen from '@/components/StateScreen.vue';

export default {
  components: {
    NavHeader,
    LayoutCard,
    StateScreen,
  },
  computed: {
    session() {
      return this.$store.getters.session;
    },
    loading() {
      return this.$store.getters.paymentHistoryLoading;
    },
    payments() {
      return this.$store.getters.paymentHistory;
    },
    restaurantName() {
      return this.$store.state.restaurant.name;
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
    await this.$store.dispatch('fetchPaymentHistory');
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async navigateToPaymentDetails(payment) {
      const orderType = payment.order_type;
      const navigateToPayment = orderType === 'pay';
      const navigateToOrder = orderType === 'order';

      if (navigateToPayment) {
        this.$router.push({
          name: 'PaymentSuccess',
          params: {
            session: this.$route.params.session,
          },
          query: {
            paymentUuid: payment.uuid,
            origin: 'PaymentHistory',
            orderNumber: payment.unique_order_number,
          },
        });
      } else if (navigateToOrder) {
        await this.$store.dispatch('fetchOrders');
        await this.$store.dispatch('openOrder', this.uniqueOrderNumber);
        this.$router.push({
          name: 'Menu',
          query: {
            order: payment.unique_order_number,
          },
          params: {
            session: this.$store.state.session,
          },
        });
      }

      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.program-details-container {
  display: flex;
  flex-flow: column;
  min-height: 100dvh;
  height: 100%;
}
</style>
