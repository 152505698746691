<template>
  <div
    v-click-outside="clickOutside"
    class="content"
    :class="{ 'content--hide': hide }"
    @click="clickInside"
  >
    <div class="left-part">
      <div
        class="cart-item-quantity-selector"
        :class="{ 'cart-item-quantity-selector--active': quantitySelectorOpen }"
      >
        <div
          class="cart-item-quantity-selector__decrease"
          @click="decreaseQuantity"
        >
          -
        </div>
        <div class="cart-item-quantity-selector__quantity tp-text-body-white">
          {{ selectedQuantity }}
        </div>
        <div
          class="cart-item-quantity-selector__increase"
          @click="increaseQuantity"
        >
          +
        </div>
      </div>
      <div class="flex">
        <span class="tp-text-subtitle-semibold">{{ $t(name) }}</span>
        <div class="order-item-modifiers">
          <div
            v-for="(modifier, key) in modifiers"
            :key="key"
            class="tp-text-body"
          >
            {{ $t(modifier.name) }}
          </div>
        </div>
      </div>
    </div>
    <div class="right-part">
      <div v-if="showSortHandle">
        <v-icon class="handle">mdi-menu</v-icon>
      </div>
      <div v-else class="tp-text-subtitle-normal">
        {{ formattedPrice }}
      </div>
    </div>
  </div>
</template>

<script>
import formatPriceWithCurrency from '@/helpers/formatPriceWithCurrency.js';
import _ from 'lodash';
export default {
  props: {
    itemKey: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    completed: {
      type: Boolean,
      required: false,
      default: false,
    },
    price: {
      type: Number,
      default: null,
      required: false,
    },
    quantity: {
      type: Number,
      default: null,
      required: false,
    },
    modifiers: {
      type: Array,
      default: null,
      required: false,
    },
    showSortHandle: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      selectedQuantity: this.quantity,
      quantitySelectorOpen: false,
      hide: false,
      quantitySelectorsDisabled: true,
    };
  },
  computed: {
    priceWithModifiers() {
      let price = 0;
      price += this.price * this.selectedQuantity;

      this.modifiers.forEach(modifier => {
        price += modifier.markup * modifier.quantity * this.selectedQuantity;
      });

      return price;
    },
    formattedPrice() {
      return formatPriceWithCurrency(
        this.priceWithModifiers,
        this.$store.state.currency.code,
        this.$store.state.locale.locale_code
      );
    },
  },
  methods: {
    async decreaseQuantity() {
      if (this.quantitySelectorsDisabled) {
        return;
      }

      if (this.selectedQuantity > 1) {
        this.selectedQuantity--;
        this.$emit('changeQuantity', {
          cart_item_id: this.id,
          quantity: this.selectedQuantity,
        });
        this.updateQuantityInBackend();
      } else {
        this.selectedQuantity = 0;
        this.$emit('changeQuantity', {
          cart_item_id: this.id,
          quantity: 0,
        });
        this.hideThisCartItem();
        await this.axios.post(`/cart/${this.$store.state.session}/remove`, {
          cart_item_id: this.id,
        });
        this.$emit('enablePayButton');
      }
    },
    increaseQuantity() {
      if (this.quantitySelectorsDisabled || this.selectedQuantity + 1 > 50) {
        return;
      }
      this.selectedQuantity++;
      this.$emit('changeQuantity', {
        cart_item_id: this.id,
        quantity: this.selectedQuantity,
      });
      this.updateQuantityInBackend();
    },
    updateQuantityInBackend: _.debounce(async function() {
      if (this.selectedQuantity > 0) {
        await this.axios.post(
          `/cart/${this.$store.state.session}/update-quantity`,
          {
            cart_item_id: this.id,
            quantity: this.selectedQuantity,
          }
        );
      }
      this.$emit('enablePayButton');
    }, 1000),
    hideThisCartItem() {
      this.hide = true;
    },
    clickInside() {
      if (this.showSortHandle) {
        return;
      }

      this.quantitySelectorOpen = true;
      setTimeout(() => {
        this.quantitySelectorsDisabled = false;
      }, 300);
    },
    clickOutside() {
      if (this.quantitySelectorOpen) {
        this.quantitySelectorOpen = false;
        this.quantitySelectorsDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  transition: height 1s, opacity 1s;
  &--hide {
    opacity: 0;
  }
  .left-part {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 0.8;
  }
  .right-part {
    display: flex;
    flex: 0.2;
    justify-content: flex-end;
  }
  .order-item-quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: var(--color-text-main);
    border-radius: 3px;
  }

  $transition-time: 0.3s;
  .cart-item-quantity-selector {
    display: flex;
    align-items: center;
    margin-right: 10px;
    width: 22px;
    transition: width $transition-time, gap $transition-time;
    gap: 0;

    &__quantity {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
      background-color: var(--color-text-main);
      border-radius: 3px;
      color: white;
      transition: width $transition-time, border-radius $transition-time,
        color $transition-time, font-weight $transition-time,
        font-size $transition-time, background-color $transition-time;
    }
    &__increase,
    &__decrease {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 0;
      height: 22px;
      background-color: $color-black;
      border-radius: 3px;
      color: white;
      line-height: 0;
      transition: width $transition-time, opacity $transition-time;
      opacity: 0;
    }

    &--active {
      width: 90px;
      gap: 15px;

      .cart-item-quantity-selector__quantity {
        width: 14px;
        background-color: inherit;
        border-radius: 0;
        color: black;
        font-weight: 500;
        font-size: 16px;
      }

      .cart-item-quantity-selector__increase,
      .cart-item-quantity-selector__decrease {
        width: 22px;
        opacity: 100;
        transition: width $transition-time, opacity $transition-time;
      }
    }
  }
}
</style>
