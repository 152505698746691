import Vue from 'vue';

export default {
  state: {
    card_type: null, // restaurant | individual
    restaurant_data: {},
    personnel_data: [],
    tips_amounts: [],
    preselected_tip_index: 0,
    max_amount: 200,
    min_amount: 1,
    display_type: 'restaurant', // restaurant | staff_members_select | staff_members_split
    picked_recipient_id: null,
    tipCardNotAvailable: false,
    locale: null,
  },
  mutations: {
    SET_PERSONNEL(state, personnelData) {
      state.personnel_data = personnelData;
    },
    SET_RESTAURANT_DATA(state, restaurant_data) {
      state.restaurant_data = restaurant_data;
    },
    SET_CARD_TYPE(state, card_type) {
      state.card_type = card_type;
    },
    SET_TIPS_AMOUNTS(state, tips_amounts) {
      state.tips_amounts = tips_amounts;
    },
    SET_MAX_AMOUNT(state, max_amount) {
      state.max_amount = max_amount;
    },
    SET_MIN_AMOUNT(state, min_amount) {
      state.min_amount = min_amount;
    },
    SET_PRESELECTED_TIP_INDEX(state, preselected_tip_index) {
      state.preselected_tip_index = preselected_tip_index;
    },
    SET_PICKED_RECIPIENT_ID(state, recipientId) {
      state.picked_recipient_id = recipientId;
    },
    SET_TIP_CARD_NOT_AVAILABLE(state, value) {
      state.tipCardNotAvailable = value || null;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
  },
  actions: {
    setTipsRecipient({ commit }, { recipientId }) {
      commit('SET_PICKED_RECIPIENT_ID', recipientId);
    },
    async fetchTipsData(
      { commit, rootState, dispatch },
      { tipsQrCardHash, personnelId = null }
    ) {
      try {
        const response = await Vue.axios.get(
          `/tips/get-data/${tipsQrCardHash}`
        );
        const responseData = response.data;

        commit('SET_RESTAURANT_DATA', responseData.restaurant_data);
        commit('SET_CARD_TYPE', responseData.card_type);
        commit('SET_PERSONNEL', responseData.personnel_data);
        commit('SET_TIPS_AMOUNTS', responseData.tips_amounts);
        commit('SET_MAX_AMOUNT', responseData.max_amount);
        commit('SET_MIN_AMOUNT', responseData.min_amount);
        commit('SET_PRESELECTED_TIP_INDEX', responseData.preselected_tip_index);

        commit('SET_PICKED_RECIPIENT_ID', personnelId);

        // TODO - this is a hack
        if (!rootState.restaurant.id) {
          const currency =
            responseData.personnel_data.currency ||
            responseData.restaurant_data.restaurant.currency;

          rootState.currency = currency;
          rootState.payment_providers = [{ provider: 'stripe' }];
        }

        const locale =
          responseData.personnel_data?.locale ||
          responseData.restaurant_data?.restaurant?.locale;

        if (locale) {
          rootState.locale = locale;
          const language = locale.locale_code.split('-')[0];

          commit('SET_LOCALE', language);

          await dispatch('changeLanguage', language);
        }
      } catch (error) {
        console.log(error);
        commit('SET_TIP_CARD_NOT_AVAILABLE', true);
      }
    },
    async generatePaymentIntent({ getters }, { amount, tipsQrCardHash }) {
      const cardType = getters.getTipsRecipientCardType;
      const selectedPersonnelId = getters.getSelectedPersonnelId;
      const isIndividual = cardType === 'individual' || selectedPersonnelId;
      const personnelId = getters.getPersonnelId;
      const personnel_id = personnelId || selectedPersonnelId;

      try {
        const response = await Vue.axios.post(
          '/payments/stripe/payment-intent-tips-v2',
          {
            personnel_id: isIndividual ? personnel_id : null,
            tips_qr_card_hash: tipsQrCardHash,
            amount,
          }
        );

        return response.data;
      } catch (error) {
        console.log(error);
      }
    },
    async generateNeoPaymentIntent({ getters }, { amount, tipsQrCardHash }) {
      const cardType = getters.getTipsRecipientCardType;
      const selectedPersonnelId = getters.getSelectedPersonnelId;
      const isIndividual = cardType === 'individual' || selectedPersonnelId;
      const personnelId = getters.getPersonnelId;
      const personnel_id = personnelId || selectedPersonnelId;

      try {
        const paymentRequest = await Vue.axios.post(
          `/payments/neopay/create-tips-transaction`,
          {
            personnel_id: isIndividual ? personnel_id : null,
            tips_qr_card_hash: tipsQrCardHash,
            amount,
          }
        );

        return paymentRequest.data;
      } catch (error) {
        console.error(error);
      }
    },
    async checkNeoPayPaymentStatus(_, { token }) {
      try {
        const response = await Vue.axios.post(
          `/payments/neopay/check-payment-status`,
          {
            token,
          }
        );

        const responseData = response.data;

        if (responseData.status === 'succeeded' && responseData.uuid) {
          const paymentDetailsResponse = await Vue.axios.get(
            `/payments/payment-by-uuid/${responseData.uuid}`
          );

          return {
            payment_id: responseData.payment_id,
            ...paymentDetailsResponse.data.data,
          };
        }

        return {
          status: 'initialized',
        };
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    tipsRecipientData(state, getters) {
      const card_type = state.card_type;
      const picked_recipient_id = state.picked_recipient_id;
      const personnelList = getters.getPersonnelList;
      const restaurant_data = state.restaurant_data;
      const hasRestaurant = !!restaurant_data?.restaurant?.name;

      if (picked_recipient_id) {
        const personnel = personnelList.find(
          personnel => `${personnel.id}` === `${picked_recipient_id}`
        );
        const data = personnel ? personnel : state.personnel_data;

        return {
          ...data,
          show_review_cta_in_payment_screen: false,
          show_review_cta_in_confirmation_screen: hasRestaurant,
        };
      }

      if (card_type === 'restaurant') {
        return {
          name: restaurant_data.restaurant.name,
          photo:
            restaurant_data.restaurant.logo || restaurant_data.restaurant.cover,
          lazySrc: restaurant_data.restaurant.thumbnail,
          id: null,
          description: null,
          show_review_cta_in_payment_screen: personnelList.length === 0,
          show_review_cta_in_confirmation_screen: true,
        };
      }

      return {
        ...state.personnel_data,
        show_review_cta_in_payment_screen: false,
        show_review_cta_in_confirmation_screen: hasRestaurant,
      };
    },
    getTipsRecipientCardType(state) {
      return state.card_type;
    },
    getPersonnelId(state) {
      return state.personnel_data.id;
    },
    getSelectedPersonnelId(state) {
      return state.picked_recipient_id;
    },
    getTipsAmounts(state) {
      return state.tips_amounts;
    },
    getMaxAmount(state) {
      return state.max_amount;
    },
    getMinAmount(state) {
      return state.min_amount;
    },
    getPreselectedTipIndex(state) {
      return state.preselected_tip_index;
    },
    getPersonnelList(state) {
      return state.restaurant_data?.personnel_list;
    },
    isStaffMembersSelectMode(state) {
      return state.display_type === 'staff_members_select';
    },
    tipsRecipientGooglePlaceId(state) {
      return state.restaurant_data.restaurant?.google_place_id;
    },
    showReviewInSummaryScreen(_, getters) {
      const tipsRecipientData = getters.tipsRecipientData;
      return tipsRecipientData.show_review_cta_in_confirmation_screen;
    },
    getTipCardNotAvailable(state) {
      return state.tipCardNotAvailable;
    },
    getWaiterLocale(state) {
      return state.locale;
    },
  },
};
