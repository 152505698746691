var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"success-icon mt-4 mb",class:{
        'success-icon--show': _vm.minifyOrderScreenAfterSuccess && _vm.orderFailed,
      },attrs:{"src":require(`@/assets/images/icons/checkmark-success.svg`)}}),_c('span',{staticClass:"tp-title-bold mt-6"},[_vm._v(_vm._s(_vm.statusData.title))])]),(_vm.$store.getters.customizationOrderInfoCustomOrderStatusText)?_c('div',{staticClass:"tp-text-body mt-3"},[_vm._v(" "+_vm._s(_vm.$t(_vm.$store.getters.customizationOrderInfoCustomOrderStatusText))+" ")]):(_vm.statusData.description)?_c('div',{staticClass:"tp-text-body mt-3"},[_vm._v(" "+_vm._s(_vm.statusData.description)+" ")]):_vm._e(),(_vm.statusData.deliveryTime)?_c('div',{staticClass:"tp-text-body mt-3"},[_vm._v(" "+_vm._s(_vm.statusData.deliveryTime)+" ")]):_vm._e(),(_vm.statusData.imageSrc)?_c('div',{staticClass:"d-flex justify-center pt-5 pb-3"},[_c('img',{staticClass:"status-image",attrs:{"src":_vm.statusData.imageSrc}})]):_vm._e(),(_vm.statusData.intermediateIndex)?_c('div',{staticClass:"status-indicators d-flex flex-row mt-5"},_vm._l((3),function(index){return _c('v-progress-linear',{key:index,staticClass:"indicator",attrs:{"indeterminate":_vm.statusData.intermediateIndex === index,"value":_vm.statusData.intermediateIndex > index ? 100 : 0,"color":"black"}})}),1):_vm._e(),(_vm.statusData.showGuestCounter)?_c('div',[_c('FloatingAvatars',{attrs:{"guest-count":_vm.guestPlacedOrdersCount,"unique-order-number":_vm.uniqueOrderNumber,"batch-activate-at":_vm.batch?.activate_at}})],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.minifyOrderScreenAfterSuccess && _vm.statusData.animationSrc),expression:"!minifyOrderScreenAfterSuccess && statusData.animationSrc"}],staticClass:"animation d-flex justify-center pt-8 pb-6",class:{
      'animation--hide':
        _vm.minifyOrderScreenAfterSuccess || !_vm.statusData.animationSrc,
    }},[_c('lottie-vue-player',{key:_vm.statusData.animationSrc,class:{
        'animation--hide': _vm.minifyOrderScreenAfterSuccess,
      },style:({
        width: _vm.statusData?.animationOptions?.width || '150px',
        height: _vm.statusData?.animationOptions?.width || '150px',
      }),attrs:{"src":_vm.statusData.animationSrc,"loop":_vm.statusData.animationOptions.loop,"autoplay":""}})],1),(_vm.statusData.disclaimerText)?_c('div',{staticClass:"tp-text-body mt-5"},[_vm._v(" "+_vm._s(_vm.statusData.disclaimerText)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }