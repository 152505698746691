<template>
  <div class="register-container">
    <NavHeader :title="$t('screens.register.navTitle')" @onBackClick="goBack" />
    <div v-if="isLoadingUser" class="py-6 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="black" />
    </div>
    <LayoutCard v-else v-show="!isLoadingUser && !user" larger-container grow>
      <GreetWallet />
      <FirebaseLogin :sign-in-success-url="signInSuccessUrl" />
    </LayoutCard>
  </div>
</template>

<script>
import NavHeader from '@/components/Navigation/NavHeader.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import FirebaseLogin from '@/components/Auth/FirebaseLogin.vue';
import GreetWallet from '@/components/Loyalty/GreetWallet.vue';

// TODO remove unused imports with their components
// import LoyaltyCard from '@/components/Loyalty/LoyaltyCard.vue';
// import RegisterUserForm from '@/components/Loyalty/RegisterUserForm.vue';

export default {
  components: {
    NavHeader,
    LayoutCard,
    FirebaseLogin,
    GreetWallet,
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    restaurantName() {
      return this.$store.getters.restaurant.name;
    },
    signInSuccessUrl() {
      const defaultUrl = `${process.env.VUE_APP_BASE_URL}/register/${this.$route.params.session}`;
      return defaultUrl;
    },
    user() {
      return this.$store.getters.user;
    },
    restaurantLoyaltyProgram() {
      const loyaltyProgramId = this.$route.params.loyaltyProgramId;
      return this.$store.getters.loyaltyProgramById(loyaltyProgramId);
    },
    userAlreadyEnrolled() {
      return this.$store.getters.userHasRestaurantLoyaltyProgram;
    },
    isLoadingUser() {
      return this.$store.state.isLoadingUser;
    },
    userHasWalletDetailsFilled() {
      return this.$store.getters.userHasWalletDetailsFilled;
    },
  },
  watch: {
    user() {
      this.handleNavigation();
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });

    this.handleNavigation();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRedirectUrl() {
      const redirectUrl = this.$route.query.redirect || 'PlaceOrder';

      this.$router.push({
        name: redirectUrl,
        params: { session: this.session },
      });
    },
    goToDetailsForm() {
      this.$router.push({
        name: 'NewUserForm',
        params: {
          session: this.session,
        },
        query: {
          redirect: this.$route.query.redirect,
        },
      });
    },
    goToEnrollLoyalty() {
      this.$router.push({
        name: 'LoyaltyPreview',
        params: {
          session: this.session,
        },
        query: {
          redirect: this.$route.query.redirect,
        },
      });
    },
    handleNavigation() {
      if (this.user && this.userAlreadyEnrolled) {
        this.goToRedirectUrl();
        return;
      } else if (this.user && !this.userHasWalletDetailsFilled) {
        this.goToDetailsForm();
        return;
      } else if (
        this.user &&
        this.userHasWalletDetailsFilled &&
        !this.userAlreadyEnrolled
      ) {
        this.goToEnrollLoyalty();
        return;
      } else if (this.user) {
        this.goToEnrollLoyalty();
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.register-container {
  display: flex;
  flex-flow: column;
  min-height: 100svh;
  height: 100%;
}
</style>
