var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-filters",class:{
    'menu-filters--controls-enabled':
      _vm.$store.getters.functionalityOrderEnabled ||
      _vm.$store.getters.functionalityPayGoEnabled,
  }},[_c('MenuHeader',{attrs:{"show-back":_vm.showBack,"on-back":_vm.goBack,"show-category-slider":false}}),(_vm.displayFilters)?_c('LayoutCard',{attrs:{"rounded":"","hide-background":!!_vm.$store.getters.customizationMenuHideLayoutCards,"use-custom-background-color":true}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.cards),function(card){return _c('v-col',{key:card.title,attrs:{"cols":card.flex}},[_c('v-card',{staticClass:"menu-filters__card",attrs:{"flat":"","height":"100%","min-height":"100%","rounded":"lg","cy-data":`menu-filter-${card.title}`},on:{"click":() => _vm.onCardClick(card)}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":card.src,"gradient":_vm.$store.getters.customizationMenuFilterCardsImageGradient,"height":"150px"}}),_c('v-card-title',{staticClass:"tp-text-subtitle menu-filters__card-text",class:{
              'menu-filters__card-text--center': !!_vm.$store.getters
                .customizationMenuFilterCardsTextCenter,
            }},[_vm._v(" "+_vm._s(_vm.$t(card.title))+" ")])],1)],1)}),1)],1):_vm._e(),_c('MoreInformation'),_c('MenuControls')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }