<template>
  <div class="pb-10">
    <NavHeader
      :title="$t('screens.profile.headerTitle')"
      @onBackClick="goBack"
    />
    <div v-if="isLoadingUser" class="py-6 d-flex justify-center align-center">
      <v-progress-circular indeterminate color="black" />
    </div>
    <div v-show="!isLoadingUser">
      <LayoutCard v-show="!user" rounded>
        <GreetWallet />
        <FirebaseLogin :sign-in-success-url="signInSuccessUrl" />
      </LayoutCard>
      <div v-if="user">
        <LayoutCard rounded>
          <div class="d-flex flex-column align-center text-center pt-4">
            <v-avatar
              :size="90"
              :color="userPhotoUrl ? undefined : 'grey lighten-1'"
            >
              <img v-if="userPhotoUrl" :src="userPhotoUrl" :alt="user.email" />
              <v-icon v-else dark x-large>
                mdi-account-circle
              </v-icon>
            </v-avatar>
            <div v-if="user.displayName" class="tp-text-title-bold pt-6">
              {{ user.displayName }}
            </div>
            <div
              class="tp-text-body grey--text text--darken-1 pt-3"
              data-cy="user-email"
            >
              {{ user?.email }}
            </div>
          </div>
          <LoyaltyCardCarousel data-cy="loyalty-cards" />
          <div class="px-6 pb-6 pt-12">
            <Button dark class="mb-5" @click="navigateToDetails">
              {{ $t('screens.profile.profileDetailsCta') }}
            </Button>
            <Button gray data-cy="logout" @click="signOut">
              {{ $t('screens.profile.signOutCta') }}
            </Button>
          </div>
        </LayoutCard>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';

import NavHeader from '@/components/Navigation/NavHeader.vue';
import Button from '@/components/Button.vue';
import LayoutCard from '@/components/LayoutCard.vue';
import FirebaseLogin from '@/components/Auth/FirebaseLogin.vue';
import LoyaltyCardCarousel from '@/components/Loyalty/LoyaltyCardCarousel';
import GreetWallet from '@/components/Loyalty/GreetWallet.vue';

export default {
  components: {
    NavHeader,
    LayoutCard,
    FirebaseLogin,
    Button,
    LoyaltyCardCarousel,
    GreetWallet,
  },
  computed: {
    session() {
      return this.$store.state.session;
    },
    signInSuccessUrl() {
      return `${process.env.VUE_APP_BASE_URL}/profile/${this.$route.params.session}`;
    },
    user() {
      return this.$store.getters.user;
    },
    userPhotoUrl() {
      return this.$store.getters.userProfilePhoto;
    },
    isLoadingUser() {
      return this.$store.getters.isLoadingUser;
    },
  },
  async mounted() {
    const sessionToken = this.$route.params.session;
    await this.$store.dispatch('fetchSessionData', { session: sessionToken });
  },
  methods: {
    async signOut() {
      const auth = getAuth();
      try {
        await auth.signOut();
      } catch (error) {
        console.error(error);
      }
    },
    navigateToDetails() {
      this.$router.push({
        name: 'ProfileDetails',
        params: { session: this.session },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
