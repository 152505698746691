<template>
  <div>
    <v-currency-field
      ref="input"
      :error-messages="errors.rate"
      :min="min"
      :max="max"
      :maxlength="5"
      background-color="grey lighten-4"
      flat
      solo
      class="money-input"
      v-bind="$attrs"
      :placeholder="$t('components.moneyInput.placeholder')"
      :autofocus="isVisible"
      :rules="[numberRule]"
      data-cy="money-amount-input"
      v-on="$listeners"
      @update:error="onErrorUpdate"
    >
      <template slot="message">
        <span class="message pa-0">
          {{
            $t('components.moneyInput.validationMessage', {
              min: min,
              max: max,
            })
          }}
        </span>
      </template>
      <template slot="append">
        <span class="black--text">
          {{ currencySymbol }}
        </span>
      </template>
    </v-currency-field>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 0.5,
    },
    max: {
      type: Number,
      default: 200,
    },
  },
  data: () => {
    return {
      isVisible: false,
      errors: {},
    };
  },
  computed: {
    currencySymbol() {
      return this.$store.getters.currencySymbol;
    },
    numberRule() {
      return v => {
        if (
          !isNaN(parseFloat(v)) &&
          parseFloat(v.replaceAll(',', '.')) >= this.min &&
          parseFloat(v.replaceAll(',', '.')) <= this.max
        ) {
          return true;
        }
        return 'validationMessage';
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isVisible = true;
    });
  },
  methods: {
    onErrorUpdate(hasErrors) {
      this.$emit('on-error-update', hasErrors);
    },
  },
};
</script>

<style lang="scss" scoped>
.money-input::v-deep {
  .v-input__control {
    display: flex;
    align-items: center;
  }

  .v-input__slot {
    width: 100px;
    input {
      color: #000000;
      font-weight: 700;
    }
    input::placeholder {
      font-weight: 700;
    }
    font-weight: 700;
  }
}
</style>
